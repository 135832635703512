import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { AdvanceEnum, AdvancePreview, ContractStatusEnum, ContractTypesEnum, CostingInvoiceReqDto, CostingInvoiceService, PriceTypeEnum, ReferenceFeatures, VendorAdvInvoiceService, VendorAdvService, vendorInvoiceIdReq } from '@exportx/shared-models-and-services';
import { Affix, Button, Card, Col, Descriptions, Divider, Form, Popconfirm, Row, Table, Tag, Tooltip } from 'antd';
import Link from 'antd/lib/typography/Link';
import moment from 'moment';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { AlertMessages, useAuthState } from '../../../../common';
import PaymentTermsView from '../../../contracts/view-components/payment-terms-view';
import AddInvoice from '../../../costings/add-invoice';
import { AdvanceReceiversService } from '@exportx/shared-models-and-services';

const AdvanceReceivablesDetailedView = () => {

  const navigate = useNavigate()
  const { formatMessage: fm } = useIntl();
  const { authContext } = useAuthState();
  const { state }: any = useLocation();
  // const { record } = state ? state : { record: null };
  const services = new AdvanceReceiversService()
  const [previewData, setPreviewData] = useState(null);
  const service = new VendorAdvInvoiceService();
  const [invoiceData, setInvoiceData] = useState<any[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [dummyRefresh, setDummyRefresh] = useState<number>(1)
  const [initialValues, setInitialValues] = useState<any>({})
  const [snapData, setSnapData] = useState<any>([]);
  const [formRef] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const location = useLocation();
  const paramBargeId = queryString.parse(location.search)?.id;
  const invoiceService = new CostingInvoiceService();
  const [adjustedData, setAdjustedData] = useState<any>()
  const vendorService = new VendorAdvService()


  let { record } = paramBargeId != null ? { record: paramBargeId } : state ? state : { record: null };



  let pathToreDirect = '/po-detail-view'

  const redirectToFullView = (contractId: string) => {
    navigate(pathToreDirect, { state: { contractId } });
  }
  useEffect(() => {
    getAdvancePreviewData(record);
    getAdvAdjustedRemainingData(record);
  }, [record]);

  const getAdvAdjustedRemainingData = (id) => {
    const req = new AdvancePreview(id, authContext.defaultPlant);
    vendorService.getAdvAdjustedRemainingData(req).then(res => {
      if (res.status) {
        setAdjustedData(res.data)
      }
    }).catch(err => console.log(err.message))
  }

  const getAdvancePreviewData = (record) => {
    const req = new AdvancePreview(record, authContext.defaultPlant);
    services.getAllAdvReceivablesData(req).then(res => {
      if (res.status) {
        setPreviewData(res.data);
        setSnapData(res.snapData);
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const setFormData = () => {
    navigate('/advance-receivables-update', { state: previewData });
  };


  const getAllCostingInvoice = () => {
    const req = new CostingInvoiceReqDto(record, authContext.defaultPlant,undefined, ReferenceFeatures.ADVANCE_INVOICE);
    invoiceService.getAllCostingInvoice(req).then(res => {
      if (res.status) {
        setInvoiceData(res.data)
      } else {
        setInvoiceData([]);
        console.log(res.internalMessage);

      };
    }).catch(err => console.log(err.message));
  };


  const valiDateWithSnapShotData = (keys: string[], value: string, isRawData: boolean): any => {
    for (const snapDate of snapData) {
      let valueAtGivenKey: any = snapDate;
      for (const key of keys) {
        valueAtGivenKey = valueAtGivenKey?.[key];
      }
      if (valueAtGivenKey == value) {
        return value;
      } else {
        if (previewData.contractTypes === ContractTypesEnum.ADDENDUM) {
          if (isRawData) {
            return value;
          } else {
            return <><s style={{ textDecorationColor: 'red', fontWeight: 'normal' }}>{valueAtGivenKey}</s>&nbsp;<Tooltip placement="top" title={snapDate.contractNo.replace('SNP', 'ADD')}><b>
              {value}
            </b></Tooltip></>
          }
        }
      }
    };
    return value
  }



  useEffect(() => {
    if (initialValues) {
      formRef.setFieldsValue(initialValues);
    }
  }, [initialValues])

  if (initialValues === undefined) {
    formRef.setFieldValue('invoicePercent', undefined);
    formRef.setFieldValue('invoiceNo', undefined);
    formRef.setFieldValue('remarks', undefined);
    formRef.setFieldValue('invoiceDate', undefined);
    formRef.setFieldValue('invoiceAmount', undefined);
    formRef.setFieldValue('invoiceId', undefined);
  }


  if (initialValues?.invoiceDate) {
    initialValues.invoiceDate = moment(initialValues?.invoiceDate)
  }


  const paymentTerms = { lc: previewData?.lc, others: previewData?.others, telegraphicTransfer: previewData?.telegraphicTransfer, lcValue: previewData?.lcValue, lcType: previewData?.lcType, lcCurrency: previewData?.lcCurrency, paymentMode: previewData?.paymentMode }
  const contractDetails = [
    {
      title: 'Vendor',
      dataIndex: 'vendorName',
      render: (value, record) => {
        const link = `/#/detailCustomer?bp_id=${record?.vendorId}`
          return <> {
          <Link href={link} className="link-primary">{valiDateWithSnapShotData(['vendorName'], value, false)}</Link>
        }
        </>
      }
    },

    {
      title: 'Contract Date',
      dataIndex: 'contractDate',
      // render: (text, record) => { return record?.contractDate !== undefined ? moment(record?.contractDate).format('DD-MM-YYYY') : "" },
      render: (value, record) => {
        const formatDate = value ? moment(value).format('DD/MM/YYYY') : '';
        return (
          <span>{valiDateWithSnapShotData(['contractDate'], formatDate, false)}</span>
        )
      }
    },
    {
      title: 'Currency',
      dataIndex: 'currencyName',
      render: (value, row: any, index) => (
        <span>{valiDateWithSnapShotData(['currencyName'], previewData?.currencyName, false)}</span>
      ),
    },
    {
      title: 'Negotiation Currency',
      dataIndex: 'negotiationCurrency',
      render: (value, row: any, index) => (
        <span>{valiDateWithSnapShotData(['negotiationCurrency'], previewData?.negotiationCurrency, false)}</span>
      ),
    },
    {
      title: 'Advance Amount',
      dataIndex: 'advanceAmount',
      render: (value, row: any, index) => (
        <span>{valiDateWithSnapShotData(['advanceAmount'], previewData?.advanceAmount, false)}</span>
      ),
    },
    {
      title: 'JISDOR Date',
      dataIndex: 'jisdorDate',
      render(value, record) {
        const formatDate = value ? moment(value).format('DD/MM/YYYY') : '';
        return (
          <span>{valiDateWithSnapShotData(['jisdorDate'], formatDate, false)}</span>
        )
    }

    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      render: (value, row: any, index) => (
        <span>{valiDateWithSnapShotData(['amount'], previewData?.amount, false)}</span>
      ),
    },

    {
      title: 'Sale Contract',
      dataIndex: 'scId',
      render: (value, record) => {
        const link = `/#/so-detail-view?sc_id=${record?.salesContract}`
        return <> {
          <Link href={link} className="link-primary">{valiDateWithSnapShotData(['scId'], previewData?.scId, false)}</Link>
        }
        </>
      }

    },
    {
      title: `${previewData?.contractTypes === 'Addendum' ? 'Base Contract' : 'Addendum Data' }`,
      dataIndex: '',
      render: (text, record) => {
        if (previewData?.contractTypes === 'Original') {
          console.log('inside if loop',)
          return (
            <div>
              {previewData?.addendumData?.map((rec) => { 
                const link = `/#/advance-receivables-detailed-view?id=${rec.id}`;
                return (
                  <a href={link} className="link-primary">
                    {`${rec.contractNo}, `}
                  </a>
                );
              })}
            </div>
          );
        } else {
          const link = `/#/advance-receivables-detailed-view?id=${previewData?.baseContractId}`;
          return (
            <a href={link} className="link-primary">
              {previewData?.baseContractNo}
            </a>
          );
        }
      }
    }

  ]


  const deduction = [
    {
      title: 'Deduction Type',
      dataIndex: 'deductionType',
      render: (value, row: any, index) => (
        <span>{valiDateWithSnapShotData(['deductionType'], previewData?.deductionType, false)}</span>
      ),
    },

    {
      title: previewData?.deductionType === PriceTypeEnum.LUMP_SUM ? 'Lumpsum Price' : 'Price PMT',
      dataIndex: 'pricePMT',
      render: (text, record) => {
        const price = record?.deductionType === PriceTypeEnum.LUMP_SUM ? record?.lumpSum : record?.pricePMT;
        return (
          <span>
            {valiDateWithSnapShotData(['pricePMT'], price, false)}
          </span>
        );
      },
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      render: (value, row: any, index) => (
        <span>{valiDateWithSnapShotData(['remarks'], previewData?.remarks, false)}</span>
      ),
    },

  ]



  const downPayments = [
    {
      title: 'Invoice No	',
      dataIndex: 'invoiceNo',
    },
    {
      title: 'Invoice Date',
      dataIndex: 'invoiceDate',
      render: (text, record) => { return record.invoiceDate !== undefined ? moment(record.invoiceDate).format('YYYY-MM-DD') : "" },
    },
    {
      title: 'Invoice %	',
      dataIndex: 'invoicePercent',
    },
    {
      title: 'Invoice Amount	',
      dataIndex: 'invoiceAmount',
    },
    {
      title: 'Action',
      dataIndex: 'Action',
      render: (value, record) =>
        // <span><EditOutlined  
        // style={{ color: '#1890ff', fontSize: '14px' }}
        // onClick={()=>{addInvoice ()}}  /> <PlusSquareOutlined onClick={() => { handlePayments() }} /></span>,
        <span>
          <Tooltip placement="topRight" title="Edit">
            <EditOutlined type="edit"
              onClick={() => editClickHandler(record)}
              style={{ color: '#1890ff', fontSize: '20px' }}
            /></Tooltip>
          <Divider type="vertical" />
          <Popconfirm
            onConfirm={e => {
              deleteInvoice(record?.id)
            }}
            title={'Are you sure to Delete Record ?'}>
            <Tooltip placement="topRight" title="Delete">
              <DeleteOutlined type='delete' style={{ color: 'red', fontSize: '20px' }} />
            </Tooltip>
          </Popconfirm>


        </span>
    },

  ];
  const Adjustments = [
    {
      title: 'Business Number',
      dataIndex: 'businessNo',
      render: (text, record) => {
        return text && text.map(rec => {
          const link = `/#/bn-detail-view?bn_Id=${rec.businessNoId}`
          return <><a href={link} className="link-primary" >
            {rec?.businessNumber}
          </a>&nbsp;</>
        })
        // render: (rec) => {
        // const link = `/#/bn-detail-view?bn_Id=${rec.businessNoId}`;
        // return <><a href={link} className="link-primary" >
        //      {rec?.businessNumber}
        //    </a>&nbsp;</>

      }
    },
    {
      title: 'Costing Number',
      dataIndex: 'costingNo',
    },
    {
      title: 'Financial Amount',
      dataIndex: 'financialTransactionAmount',
      onCell: (record: any) => ({
        rowSpan: record.rowSpan
      }),
    },
    {
      title: 'Adjusted Amount',
      dataIndex: 'adjustedAmount',
    },

    {
      title: 'Remaining Paid Advance',
      dataIndex: 'remainingAdvance',
      onCell: (record: any) => ({
        rowSpan: record.rowSpan
      }),
    },
    {
      title: 'Un Paid Amount',
      dataIndex: 'unPaidAmount',
      onCell: (record: any) => ({
        rowSpan: record.rowSpan
      }),
    },
  ];


  const getTableData = (values: any) => {
    const empty = [];
    [values]?.forEach((rec) => {
      if (rec?.adjustedData?.length) {
        rec?.adjustedData?.forEach((record, index) => {
          const remainingAdvance = Number(rec?.financialTransactionAmount) - Number(rec?.totalAdjustedAmountOfAllCostings)
          const unPaidAmount = previewData?.amount - rec?.financialTransactionAmount

          empty.push({
            businessNo: record.businessNo,
            costingNo: record.costingNo,
            adjustedAmount: record.adjustedAmount,
            rowSpan: index === 0 ? adjustedData.adjustedData.length : 0,
            financialTransactionAmount: rec.financialTransactionAmount,
            remainingAdvance: remainingAdvance ? remainingAdvance : 0,
            unPaidAmount: unPaidAmount ? unPaidAmount : 0
            //  previewData?.amount - rec.transactionTotal
          })
        });
      } else {
        const remainingAdvance = Number(rec?.financialTransactionAmount) - Number(rec?.totalAdjustedAmountOfAllCostings)
        const unPaidAmount = previewData?.amount - rec?.financialTransactionAmount
        empty.push({
          businessNo: '',
          costingNo: '',
          adjustedAmount: '',
          rowSpan: 1,
          financialTransactionAmount: rec?.financialTransactionAmount,
          remainingAdvance: remainingAdvance ? remainingAdvance : 0,
          unPaidAmount: unPaidAmount ? unPaidAmount : 0
          // previewData?.amount - rec?.transactionTotal
        })
      }


    });
    return empty;
  }


  const addInvoice = () => {
    setIsModalOpen(true);
    setInitialValues(undefined);
    formRef.setFieldValue('id', undefined)
    fieldsReset();
    setDummyRefresh(prev => prev + 1);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setInitialValues(undefined);
    setDummyRefresh(prev => prev + 1)
    fieldsReset();
  };

  const editClickHandler = (record) => {
    setDummyRefresh(prev => prev + 1);
    setInitialValues(record);
    setIsModalOpen(true);
  };



  const fieldsReset = () => {
    formRef.resetFields();
    setFileList([])
  };


  useEffect(() => {
    getAllCostingInvoice();
  }, []);

  const addendumClickHandler = () => {
    const link = `/advance-receivables-addendum?Id=${record}` 
    navigate(link, { state: previewData})
  }

  const deleteInvoice = (id: string) => {
    const req = new vendorInvoiceIdReq(id, authContext.defaultPlant,)
    service.deleteInvoice(req).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage);
        getAllCostingInvoice();
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);

      }
    }).catch(err => console.log(err.message))
  }

  const advanceReceivableSendForApproval = (id: string) => {
    const req = new AdvancePreview(id, authContext.defaultPlant, authContext.user.userName,);
    services.advanceReceivableSendForApproval(req).then(res => {
      if (res.status) {
        getAdvancePreviewData(record);
        getAdvAdjustedRemainingData(record);
        AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })

  }
  const advanceReceivableApproveOrReject = (id: string, status: ContractStatusEnum) => {
    if (!id) {
      AlertMessages.getErrorMessage(fm({ id: ``, defaultMessage: 'Contract id not found' }));
      return;

    }
    const req = new AdvancePreview(id, authContext.defaultPlant, authContext.user.userName, status, previewData.contractTypes);
    services.advanceReceivableApproveOrReject(req).then(res => {
      if (res.status) {
        getAdvancePreviewData(record);
        getAdvAdjustedRemainingData(record);
        AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })

  }

  const getExtraComponent = (status: any) => {
    if (status === ContractStatusEnum.DRAFT) {
      return <><Button onClick={() => advanceReceivableSendForApproval(previewData?.id)}>Send For Approval</Button></>
    }
    if (status === ContractStatusEnum.PENDING_APPROVAL) {
      return <><Button size="small" onClick={() => advanceReceivableApproveOrReject(previewData?.id, ContractStatusEnum.ACTIVE)}>Approve</Button> &nbsp;&nbsp;&nbsp;
        <Button size="small" onClick={() => advanceReceivableApproveOrReject(previewData?.id, ContractStatusEnum.CANCELLED)}>Reject</Button>
      </>;
    }
    if(status === ContractStatusEnum.ACTIVE && previewData?.contractTypes === ContractTypesEnum.ORIGINAL){
      return <Button onClick={() => addendumClickHandler()}>Create Addendum</Button>
    }

  }


  return (
    <div >
      <Card>
        <Card>
          <Card
            // title={<span style={{ color: 'white' }}>Contract Details-{previewData?.contractNo}</span>}
            //   headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<span style={{ color: 'white' }}></span>}
            title={<span >Contract Details - {previewData?.['contractNo']} <Tag color="#55acee">
              {previewData?.['status']}
            </Tag></span>}
            style={{ textAlign: 'center' }}

            headStyle={{ backgroundColor: '#69c0ff', border: 0, color: '#fff' }}
            extra={<span style={{ color: 'white' }}>{getExtraComponent(previewData?.['status'])}</span>}

          >
            <Row>
              <Col span={24}>
                <Descriptions
                  title=""
                  column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                >
                  <Descriptions.Item label="Approved By">
                    {previewData?.['approvedBy']}
                    {/* {valiDateWithSnapShotData(['shippingTerms', 'loadingRateForGeared'], shippingTerms?.loadingRateForGeared, false)} */}
                  </Descriptions.Item>
                  <Descriptions.Item label="Approved On">
                    {previewData?.['approvedOn'] ? moment(previewData?.['approvedOn']).format('DD-MM-YYYY H:mm A') : ''}
                  </Descriptions.Item>
                </Descriptions></Col>
            </Row>
            <Table dataSource={Array(previewData)} columns={contractDetails} pagination={false} bordered /><br></br>
            <Table dataSource={Array(previewData)} columns={deduction} pagination={false} bordered /><br></br>
            <Card >
              <PaymentTermsView paymentTerms={paymentTerms} />
            </Card>
          </Card><br />

          {/*no need for Payment notice this one is need for receivables   */}
          < AddInvoice
            costingData={previewData}
            vendorAdvanceType={ReferenceFeatures.ADVANCE_INVOICE}
            vendorId={previewData?.vendorId}
            contractNo={previewData?.contractNo}
            advanceType={AdvanceEnum.AdvanceReceivables}
          />



          <Card title={<span style={{ color: 'white' }}>Down Payment Adjustments</span>}
            headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<span style={{ color: 'white' }}></span>}>
            <Table columns={Adjustments} dataSource={getTableData(adjustedData)}
              pagination={false} bordered />
          </Card>

          <Affix offsetBottom={0}>
            <Card bodyStyle={{ padding: '6px 12px' }}>
              <Row justify='space-between'>
                <Col><Button onClick={() => { navigate(-1) }}>Back</Button></Col>
                {previewData?.['status'] !== ContractStatusEnum.ACTIVE &&
                <Col><Button onClick={setFormData}>Edit</Button></Col>
                }
              </Row>
            </Card>
          </Affix>
        </Card>



      </Card>
      {/* <Modal open=isOpen}>
        <PaymentPosting />

      </Modal> */}
    </div>
  )
}

export default AdvanceReceivablesDetailedView



